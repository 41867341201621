import * as React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@mui/material";
import CclUnrestrictedOutlinedButton from "../../components/common/cclButtons/cclUnrestrictedOutlinedButton";
import { SessionCalendarEvent } from "../../services/types/sessionDetailTypes";
import CclCommonLink from "../../components/common/cclCommonLink";

export interface CalendarSessionDetailDialogProps {
    open: boolean;
    evt: SessionCalendarEvent | null;
    onClose: () => void;
}

export const CalendarSessionDetailDialog: React.FC<CalendarSessionDetailDialogProps> = (props) => {
    function getCellSx(isLabel: boolean) {
        return isLabel
            ? {
                  fontSize: 14,
                  borderBottom: "transparent",
                  width: "fi",
              }
            : {
                  fontWeight: "bold",
                  fontSize: 14,
                  borderBottom: "transparent",
                  padding: "3px",
              };
    }

    function getFormattedDateForCell(dt: Date | undefined | null) {
        return dt != null
            ? new Date(dt).toLocaleString("default", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
              })
            : "-";
    }

    return (
        <Dialog open={props.open} maxWidth={"sm"} fullWidth={true}>
            <DialogTitle sx={{ borderBottom: "solid 1px gray", fontWeight: "bold" }}>
                {props.evt?.title ?? "Error: No Session Selected"}
            </DialogTitle>
            <DialogContent sx={{ marginTop: "10px" }}>
                <Stack spacing={6} direction="row">
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell sx={getCellSx(true)}>ID:</TableCell>
                                <TableCell sx={getCellSx(false)}>
                                    {props.evt?.programId != null ? (
                                        <CclCommonLink
                                            text={props.evt.programId.toString()}
                                            keyVal={`id-${props.evt.programId}`}
                                            to={`/session/${props.evt.programId}`}
                                        />
                                    ) : (
                                        "-"
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={getCellSx(true)}>Start Date:</TableCell>
                                <TableCell sx={getCellSx(false)}>
                                    {getFormattedDateForCell(props.evt?.startDate)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={getCellSx(true)}>Score Date:</TableCell>
                                <TableCell sx={getCellSx(false)}>
                                    {getFormattedDateForCell(props.evt?.scoreDate)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell sx={getCellSx(true)}>Code:</TableCell>
                                <TableCell sx={getCellSx(false)}>
                                    {props.evt?.programId != null &&
                                    props.evt?.programCode != null ? (
                                        <CclCommonLink
                                            text={props.evt.programCode}
                                            keyVal={`code-${props.evt.programId}`}
                                            to={`/session/${props.evt.programId}`}
                                        />
                                    ) : (
                                        "-"
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={getCellSx(true)}>End Date:</TableCell>
                                <TableCell sx={getCellSx(false)}>
                                    {getFormattedDateForCell(props.evt?.endDate)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={getCellSx(true)}>Campus:</TableCell>
                                <TableCell sx={getCellSx(false)}>
                                    {props.evt?.campusAbbreviation}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Stack>
            </DialogContent>
            <DialogActions>
                <CclUnrestrictedOutlinedButton onClick={props.onClose}>
                    Close
                </CclUnrestrictedOutlinedButton>
            </DialogActions>
        </Dialog>
    );
};

export default CalendarSessionDetailDialog;
